<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <!-- MOSTRAR FORM -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('menu.tarifas') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="item.name"
                :label="$t('lbl.name')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <!--<v-select
                v-model="item.aerolinea_id"
                :items="aerolineas"
                :label="$t('lbl.aerolinea')"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-select>-->
              <v-autocomplete
                v-model="item.aerolinea_id"
                :items="itemsAerolinea"
                :search-input.sync="searchAerolinea"
                hide-details
                hide-selected
                :label="$t('lbl.aerolinea')"
                outlined
                dense
                item-text="name"
                item-value="id"
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.operador') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="item.clase_id"
                :items="clases"
                :label="$t('lbl.clase')"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="save"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          {{ $t('msg.deleteItem', { item: item.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('nom_tarifa_flights:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <!--@change="changeAerolinea"-->
          <v-autocomplete
            v-model="model.aerolinea_id"
            class="pt-2"
            :items="itemsAerolineaF"
            :search-input.sync="searchAerolineaF"
            hide-details
            hide-selected
            :label="$t('lbl.aerolinea')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="changeAerolinea"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.aerolinea') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="model.clase_id"
            class="pt-2"
            :items="itemsClases"
            :search-input.sync="searchClases"
            hide-details
            hide-selected
            :label="$t('lbl.clase')"
            outlined
            dense
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.clase') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="model.aerolinea_id || model.clase_id"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="searchAll()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.tarifas') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <th class="text-uppercase">
              {{ $t('menu.aerolinea') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.clase') }}
            </th>
            <th
              v-if="
                permisos.includes('nom_tarifa_flights:edit') ||
                  permisos.includes('nom_tarifa_flights:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>{{ iten.name }}</td>
            <td>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  sm="2"
                >
                  <v-avatar
                    v-if="iten.aerolinea.isotipo === null"
                    color="primary"
                    size="35"
                  >
                    <span class="white--text text-h5">{{ iten.aerolinea.slug }}</span>
                  </v-avatar>
                  <v-img
                    v-else
                    class="pt-5"
                    :src="rutaPublic + iten.aerolinea.isotipo"
                    :alt="iten.aerolinea.slug"
                    width="60"
                    contain
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                  sm="10"
                >
                  <p class="pt-2">
                    {{ iten.aerolinea.name }}
                  </p>
                </v-col>
              </v-row>
            </td>
            <td>{{ iten.clase !== null ? iten.clase.name : '' }}</td>
            <td
              v-if="
                permisos.includes('nom_tarifa_flights:edit') ||
                  permisos.includes('nom_tarifa_flights:destroy') ||
                  permisos.includes('*')
              "
            >
              <v-tooltip
                v-if="permisos.includes('nom_tarifa_flights:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="permisos.includes('nom_tarifa_flights:destroy') || permisos.includes('*')"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEraserVariant,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      itemsAll: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEraserVariant,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      model: {},
      item: {},
      avatar: null,
      itemId: null,
      abilities: [],
      abilityList: [],
      aerolineas: [],
      itemsAerolinea: [],
      itemsAerolineaF: [],
      searchAerolinea: null,
      searchAerolineaF: null,
      clases: [],
      itemsClases: [],
      searchClases: null,
      checkPermiso: 1,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  watch: {
    searchAerolinea(val) {
      this.filterAerolinea(val.toLowerCase())
    },
    searchAerolineaF(val) {
      this.filterAerolineaF(val.toLowerCase())
    },
    searchClases(val) {
      this.filterClases(val.toLowerCase())
    },
  },
  created() {
    this.profile()
    this.getAerolineas()

    this.getClases()
    this.load()
    this.loadAll()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    clearSearch() {
      this.model = {}
      this.load()
    },
    searchAll() {
      this.pagination.current = 1
      this.load()
    },
    load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('nom_tarifa_flights/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('nom_tarifa_flights/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.itemsAll = res.data.data
            this.totalItems = res.data.meta.total
          }
        })
    },
    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.aerolineas = res.data.data
          }
        })
    },
    filterAerolinea(v) {
      this.itemsAerolinea = []
      if (v === '') {
        this.itemsAerolinea = []
      } else {
        this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    filterAerolineaF(v) {
      this.itemsAerolineaF = []
      if (v === '') {
        this.itemsAerolineaF = []
      } else {
        this.itemsAerolineaF = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    getClases() {
      this.axios
        .get(`nom_clase_flights?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.clases = res.data.data
          }
        })
    },
    filterClases() {
      this.itemsClases = this.clases.filter(e => e.name.toLowerCase())
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      this.isDialogVisible = true
      this.item = {}
      this.itemId = null
    },
    editItem(item) {
      this.isDialogVisible = true
      this.item = item
      this.itemId = item.id

      this.filterAerolinea(item.aerolinea.name.toLowerCase())

      // this.clases = this.aerolineas.filter(e => e.id === this.item.aerolinea_id)[0].clases
    },
    save() {
      if (this.item.name && this.item.aerolinea_id && this.item.clase_id) {
        this.loading = true
        this.item.perfil_slug = sessionStorage.getItem('perfil')

        if (this.itemId === null) {
          this.axios
            .post('nom_tarifa_flights', this.item, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.tarifaVueloExist', { identificador: this.item.name }))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        } else {
          this.axios
            .put(`nom_tarifa_flights/${this.itemId}`, this.item, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`nom_tarifa_flights/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },

    changeAerolinea() {
      if (this.model.aerolinea_id) {
        const ids = []
        this.clases = []
        this.itemsClases = []

        this.itemsAll.forEach(element => {
          if (!ids.includes(element.clase_id)) {
            if (this.model.aerolinea_id === element.aerolinea_id) {
              ids.push(element.clase_id)
              this.clases.push(element.clase)
              this.itemsClases.push(element.clase)
            }
          }
        })

        // this.clases = this.aerolineas.filter(e => e.id === this.model.aerolinea_id)[0].clases
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
